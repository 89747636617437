import React from 'react';
import Board from './components/board/Board'
import './App.css';

function App() {
  return (
    <div className="App">
      <Board />

    </div>
  );
}

export default App;
